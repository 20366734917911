<template>
  <div>
    <b-card>
      <div class="row mb-3">
        <div class="col-12 d-flex">
          <span class="h2 mr-auto text-secondary"> Nouvelle capacité SI</span>

          <div>
            <modal-actions @close="$router.go(-1)" />
          </div>
        </div>
      </div>
      <div class="shdow">
        <div
          class="d-flex flex-wrap justify-content-between align-items-center"
        >
          <span class="h3 text-secondary">Informations générales</span>
          <div>
            <b-button
              @click="createItem"
              type="submit"
              pill
              :disabled="submitingForm"
            >
              <b-spinner
                small
                v-if="submitingForm"
                class="text-white"
                label="Loading..."
              ></b-spinner>
              Confirmer</b-button
            >
          </div>
        </div>
        <hr class="mt-1 mb-3 bg-secondary" />

        <div class="row">
          <!-- First Col -->
          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label="Libellé : "
              label-class="font-weight-bold"
            >
              <b-form-input
                v-model="newCapacite.name"
                :class="{
                  'is-invalid':
                    $v.newCapacite.name.$error && $v.newCapacite.name.$dirty,
                }"
                placeholder="Libellé"
                type="text"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.name.$dirty"
              >
                {{
                  !$v.newCapacite.name.required
                    ? "Champ obligatoire"
                    : !$v.newCapacite.name.minLength
                    ? `Le champ doit contenir au moins ${$v.newCapacite.name.$params.minLength.min} caractères.`
                    : ""
                }}
              </span>
              <!--  -->
            </b-form-group>
            <b-form-group
              label-cols="4"
              label="Description : "
              label-class="font-weight-bold"
            >
              <b-form-input
                placeholder="Description"
                v-model="newCapacite.description"
                type="text"
              ></b-form-input>
            </b-form-group>

            <b-form-group
              label-cols="4"
              label="Capacité de rattachement : "
              label-class="font-weight-bold"
            >
              <v-select
                label="name"
                :reduce="(capacite) => capacite['@id']"
                v-model="newCapacite.parent"
                :options="capaciteRatechements"
                placeholder="Capacité de rattachement"
                @input="
                  () => {
                    newCapacite.niveau = newCapacite.parent ? 2 : 1;
                  }
                "
              ></v-select>
            </b-form-group>
          </div>

          <div class="col-12 col-md-6">
            <b-form-group
              label-cols="4"
              label="Niveau : "
              label-class="font-weight-bold"
            >
              <b-form-input
                :class="{
                  'is-invalid':
                    $v.newCapacite.niveau.$error &&
                    $v.newCapacite.niveau.$dirty,
                }"
                v-model="newCapacite.niveau"
                disabled
                type="number"
                placeholder="Niveau"
              ></b-form-input>
              <!-- ERROR MESSAGES -->
              <span
                style="font-size: x-small"
                class="text-danger font-weight-normal"
                v-if="$v.newCapacite.niveau.$dirty"
              >
                {{ !$v.newCapacite.niveau.required ? "Champ obligatoire" : "" }}
              </span>
            </b-form-group>
          </div>
        </div>
      </div>
      <hr />

      <div>
        <b-tabs class="tab-solid tab-solid-primary mt-3">
          <b-tab title="Applications rattachées"
            ><ApplicationsTable :applications="[]" disabled
          /></b-tab>
          <b-tab title="Cadres rattachés"><CadresTable disabled /></b-tab
        ></b-tabs>
      </div>
    </b-card>
  </div>
</template>

<script>
import ModalActions from "@/components/custom/ModalActions.vue";
import { required, minLength } from "vuelidate/lib/validators"; //validation
import Swal from "sweetalert2";
import { mapGetters } from "vuex";

import ApplicationsTable from "./components/ApplicationsTable";
import CadresTable from "./components/CadresTable";
export default {
  components: {
    ModalActions,
    ApplicationsTable,
    CadresTable,
  },
  data: () => ({
    editMode: false,
    submitingForm: false,
    newCapacite: {
      name: "",
      description: "",
      parent: null,
      niveau: 1,
      applications: [],
    },
  }),
  validations: {
    newCapacite: {
      name: {
        required,
        minLength: minLength(5),
      },

      niveau: {
        required,
      },

      // applications: {
      //   required,
      // },
    },
  },
  methods: {
    createItem() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.submitingForm = true;
        this.$store
          .dispatch("capaciteSi/createCapacite", {
            name: this.newCapacite.name,
            parent: this.newCapacite.parent,
            description: this.newCapacite.description,
            isPrincipal: false,
            applications: this.newCapacite.applications.map(
              (application) => application["@id"]
            ),
            niveau: parseInt(this.newCapacite.niveau),
          })
          .then(() => {
            this.submitingForm = false;
          });
      }
    },
  },
  created() {
    this.$store.dispatch("capaciteSi/fetchAllCapacites");
    this.$store.dispatch("application/fetchAllApplications");
  },
  computed: {
    ...mapGetters("capaciteSi", ["CAPACITE_SI", "CAPACITES_SI"]),
    ...mapGetters("application", ["APPLICATIONS"]),
    capaciteRatechements() {
      return this.CAPACITES_SI.filter((capacite) => capacite.niveau === 1);
    },
    applicationsList() {
      return this.APPLICATIONS;
    },
  },
  watch: {
    CAPACITE_SI() {
      this.submitingForm = false;

      Swal.fire({
        title: "La capacité SI est bien créée !",
        type: "success",
        confirmButtonText: "OK",
      }).then((result) => {
        if (result.value) {
          this.$router.push({
            name: "show-infosys-capacity",
            params: {
              slug: this.CAPACITE_SI.slug,
              id: this.CAPACITE_SI.id,
              fromCreation: true,
            },
          });
        }
      });
    },
  },
};
</script>

<style></style>
